export default [
    {
        path: '/server-flats-big',
        name: 'server-flats-big',
        meta: {
            title: "Квартиры",
            menu: {
                name: "Квартиры",
                role: "project.admin",
                project: "server.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "server-project-flats_big" */ "@/views/server-project-flats_big")
    },
    // {
    //     path: '/server-flats-small',
    //     name: 'server-flats-small',
    //     meta: {
    //         title: "Дом",
    //         menu: {
    //             name: "Дом",
    //             role: "project.admin",
    //             project: "server.project",
    //             index: 10
    //         }
    //     },
    //     component: () => imexp(/* webpackChunkName: "server-flats-small" */ "@/site/modules/server/flats_list_small/index")
    // },
    {
        path: '/server-flats-extract',
        name: 'server-flats-extract',
        meta: {
            title: "Выписки",
            menu: {
                name: "Выписки",
                role: "project.admin",
                project: "server.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "server-project-extract" */ "@/views/server-project-extract")
    },
    {
        path: '/server-flats-imexp',
        name: 'server-flats-imexp',
        meta: {
            title: "Импорт-экпорт",
            menu: {
                name: "Импорт-экпорт",
                role: "project.admin",
                project: "server.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "server-project-imexp" */ "@/views/server-project-imexp")
    },
    {
        path: '/server-setup',
        name: 'server-setup',
        meta: {
            title: "Настройки",
            menu: {
                name: "Настройки",
                role: "project.admin",
                project: "server.project",
                index: 10
            }
        },
        component: () => import(/* webpackChunkName: "server-project-setup" */ "@/views/server-project-setup")
    },
]

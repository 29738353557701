import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    lang: localStorage.getItem('lang') || null,
    develop: process.env.NODE_ENV === 'development',
    // load:{loading:false, mess:''},
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token
    },
    destroyToken(state) {
      state.token = null
    },
  },
  actions: {
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    token: state => state.token,
    lang: state => state.lang,
  }
})

"use strict";

import Vue from 'vue';
import axios from "axios";
import store from "@/store"


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = 'http://test.spaceengineers.world'// process.env.baseURL || process.env.apiUrl || '';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://127.0.0.1:8000'  // process.env.baseURL || process.env.apiUrl || '';
    store.state.domen = axios.defaults.baseURL
} else {
    axios.defaults.baseURL = ''  // process.env.baseURL || process.env.apiUrl || '';
    store.state.domen = '' // window.location.host
}

// window.console.log("========= process.env ========", process.env)
// window.console.log("========= isDEV ========", process.env.NODE_ENV === 'development')


// axios.defaults.baseURL = ''
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common['Authorization'] = ' Token ' + store.state.token;
// alert(process.env.baseURL)

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
    onUploadProgress: event => console.log(event.loaded)
};

// axios.put("/api", data, config);

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        const token = store.getters.token;
        if (token)
            config.headers.common['Authorization'] = ` Token ${token}`;

        const lang = store.getters.lang;
        if (lang)
            config.headers.common['Accept-Language'] = lang;
        // Accept-Language: ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7
        if (config.zLoader) config.zLoader[0][config.zLoader[1]] = true
        return config;
    },
    function (error) {
        // alert('Do something with request error')
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // alert('Do something with response data')
        let config = response.config
        if (config.zLoader) config.zLoader[0][config.zLoader[1]] = false
        return response;
    },
    function (error) {
        window.console.log('===error.response====', error.response)
        let config = error.config
        if (config.zLoader) config.zLoader[0][config.zLoader[1]] = false

        // Отдельно обрабатываем ошибки DRF при неправильном вводе значения в форму
        if (error.response.status === 400) {
            let err = window.vue.$lib.getDRFerrorText(error.response.data)
            let params = {"title": 'Неверный запрос', 'text': err}
            window.vue.$bus.emit('snackbar', params);
        } else if (error.response.status === 500) {
            let params = {"error": error, 'text': 'Неизвестная ошибка на сервере'}
            window.vue.$bus.emit('snackbar', params);
        } else if (error.response.status === 403) {
            let params = {"title": 'Отказ в доступе', 'text': error.response.data.detail}
            window.vue.$bus.emit('snackbar', params);
        } else {
            let params = {"error": error, 'text': error.response.data.detail}
            window.vue.$bus.emit('snackbar', params);
        }
        // else if (!(error.response.status === 403 && error.response.data.code === 'ProjectPermission')) {
        //     // Показываем сообщения на все ошибки КРОМЕ ошибки доступа проекта к ресурсу (она в консоле)
        //     let params = {"error": error, 'text': error.response.data.detail}
        //     window.vue.$bus.emit('snackbar', params);
        // }
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    options
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default {Plugin, _axios};


/*



////////////////////////////

      this.$axios.get(this.$url.XXXXX, {params: {name: 'playersTop'}, zLoader: [this, 'sparkline_loading']})
          .then(response => {
            this.desserts = response.data
            this.sparkline_loading = false
          })

      this.$axios.get(this.$url.server_project_flat_residents.replace('55555', this.flat.id), {zLoader: [this, 'wait']})
          .then(response => {this.items = response.data})

///////////////////////////////////

      // используй this.$lib.compare(this.flat_edit,this.flat) для отправки только различий
      this.$axios.patch(this.$url.server_project_flat.replace('55555', this.flat.id), this.flat_edit, {zLoader: [this, 'loading']})
          .then(response => {
            this.$lib.restore(this.flat, response.data)
            this.show = false
          })

        this.$axios.patch(this.$url.test_user_test1 + `${this.editedItem.id}/`, this.editedItem, {params: {key: this.key}})
            .then(response => {
              response
              Object.assign(this.desserts[this.editedIndex], this.editedItem)
            })

\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

      this.$axios.post(this.$url.core_mix_projects_list,this.project_new, {zLoader: [this, 'sparkline_loading']})
          .then(response => {
            window.console.log('===GOOD====', response.data)
          })
          .catch(error => {
            window.console.log('===ERROR====', error.response)
            this.alert_text = error.response.data.detail
          })


      this.$axios.post(this.$url.server_user_karma.replace('55555', this.player.id), {"value": this.slider_value*this.karma_direct})
          .then(response => {
            this.player.karma = response.data
            this.$emit('closeOK', this.player)// чтобы пользователь обработал закрытие
            this.params.activityCredit -= this.slider_value
            this.loading = false
            this.show = false
          })

        this.$axios.post(this.$url.test_user_test1,JSON.parse(this.editedItem.userJSON), {params: {key: this.key}, zLoader: [this, 'sparkline_loading']})
            .then(response => {
              this.editedItem = response.data
              this.desserts.unshift(this.editedItem)
            })

///////////////////////////////////


      this.$axios.delete(this.$url.test_user_test1 + `${this.editedItem.id}/`, {params: {key: this.key}})
          .then(response => {
            response
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
          })

       this.$axios.patch(this.$url.server_project_flat.replace('55555', this.resident.id), {deleted: false}, {zLoader: [this, 'loading']})
          .then(response => {
             this.$lib.restore(this.flat, response.data)
             this.show = false
          })

/////////////////////////////////

Пример возврата ошибки с поля

{
    "doc": {
        "rightRegDate": [
            "Неправильный формат date. Используйте один из этих форматов: YYYY-MM-DD."
        ]
    }
}
 */

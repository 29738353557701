export default [
    {
        path: '/password/reset/confirm/:uid/:token',
        // /account/password/reset/confirm/MTk/an1zj7-79525f0429af22f3cf4bde3163e6c210
        // alias:'/login',
        name: 'password_reset',
        meta: {public: true, title: "Восстановление пароля"},
        component: () => import(/* webpackChunkName: "login" */ '@/views/accounts/index.vue')
    },
    {
        path: '/logon',
        name: 'login_pass',
        meta: {public: true, title: "Вход в систему, пароль"},
        component: () => import(/* webpackChunkName: "login" */ '@/views/accounts/index.vue')
    },
    {
        path: '/login',
        name: 'login_soc',
        meta: {public: true, title: "Вход в систему, соцсети"},
        component: () => import(/* webpackChunkName: "login" */ '@/views/accounts/index.vue')
    },
]
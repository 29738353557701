import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


function detectLanguage( lang ) {
  if (lang) return lang
  let currentLang = localStorage.getItem("lang") || null;
  if (!currentLang) {
    currentLang = window.navigator.language.slice(0, 2)
    localStorage.setItem("lang", currentLang)
    // alert(currentLang)
  }
  return currentLang
}


export default new VueI18n({
  locale: detectLanguage(), // 'en' в параметре
  fallbackLocale: 'none',//process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages: loadLocaleMessages()
})

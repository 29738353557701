
export default {
    start: "/core/api/v1/user/start",
    project: "/core/api/v1/user/project",
    rest_login: "/accounts/api/v1/login/",
    rest_logout: "/accounts/api/v1/logout/",
    rest_password_reset: "/accounts/api/v1/password/reset/",
    rest_password_reset_confirm: "/accounts/api/v1/password/reset/confirm/",
    rest_password_change: "/accounts/api/v1/password/change/",
    registration: "/accounts/registration/",
    steam_login: "/accounts/steam/login/?process=login",
    core_mix_projects: "/core/api/v1/router/mix/projects/",
    core_project_users: "/core/api/v1/router/project/users/",
    core_project_find_user: "/core/api/v1/project/find_user",
    core_project_roles: "/core/api/v1/project/roles",
    core_project_modules: "/core/api/v1/project/modules",
    server_project_flats: "/server/api/v1/project/flats",
    server_project_flat: "/server/api/v1/project/flat/55555",
    server_project_catalog: "/server/api/v1/project/catalog",
    server_project_flat_residents: "/server/api/v1/project/flat/55555/residents",
    server_project_flat_log: "/server/api/v1/project/flat/55555/log",
    server_project_download: "/server/api/v1/project/download",
    server_project_upload: "/server/api/v1/project/upload",
    server_project_uploadExtract: "/server/api/v1/project/uploadExtract",
    server_project_extractToHTML: "/server/api/v1/project/extractToHTML",
    server_project_extractSave: "/server/api/v1/project/extractSave",
    server_project_rosreestr_key: "/server/api/v1/project/rosreestr_key",
    server_residents: "/server/api/v1/router/residents/",
}

//

